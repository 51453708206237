import React, { useState, useEffect } from 'react';
import './PricingSection.css';

const PricingSection = () => {
    const [headingVisible, setHeadingVisible] = useState(false);
    const [contentVisible, setContentVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const section = document.querySelector('.pricing-section');
            const sectionPosition = section.getBoundingClientRect().top;
            const screenPosition = window.innerHeight / 1.5;

            if (sectionPosition < screenPosition) {
                setHeadingVisible(true);
                setTimeout(() => {
                    setContentVisible(true);
                }, 500); // Delay to show the content after heading is visible
                window.removeEventListener('scroll', handleScroll);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const pricingCards = [
        {
            type: 'Basic',
            color: '#ffffff',
            features: ['Floor Plan', 'Electrical Drawings', 'Plumbing Drawings'],
            slashedPrice: 'INR 50,000',
            actualPrice: 'INR 30,000',
        },
        {
            type: 'Standard',
            color: '#ffffff',
            features: [
                'Everything in basic and',
                'Structural Drawings',
                'Elevation Drawings',
                'Material Quantity Sheets',
                'Manpower and execution timeline guide',
            ],
            slashedPrice: 'INR 75,000',
            actualPrice: 'INR 60,000',
        },
        {
            type: 'Premium',
            color: '#ffffff',
            features: [
                'Everything in standard and',
                'Material Samples',
                'Interior Mood Board',
                'Execution Checklist',
            ],
            slashedPrice: 'INR 1,00,000',
            actualPrice: 'INR 77,000',
        },
    ];

    return (
        <div className="pricing-section" id="Pricing">
            <h2 className={`pricing-heading ${headingVisible ? 'appear' : ''}`}>Fixed prices, no surprises</h2>
            <div className={`pricing-cards-container ${contentVisible ? 'visible' : ''}`}>
                {pricingCards.map((card, index) => (
                    <div className="pricing-card-wrapper" key={index}>
                        <div className="pricing-card">
                            <div className="pricing-card-header" style={{ backgroundColor: card.color }}>
                                {card.type}
                            </div>
                            <div className="pricing-card-content">
                                <ul>
                                    {card.features.map((feature, i) => (
                                        <li key={i}>{feature}</li>
                                    ))}
                                </ul>
                                <div className="pricing-card-price">
                                    <div className="slashed-price">{card.slashedPrice}</div>
                                    <div className="actual-price" style={{ color: "#000000", fontFamily: "SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif" }}>
                                        {card.actualPrice}
                                    </div>
                                </div>
                            </div>
                            <div className="pricing-card-footer" style={{ backgroundColor: card.color }}>
                                {/* Add any footer content if needed */}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PricingSection;
